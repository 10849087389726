import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { trpc } from './data-loaders/trpc';
import { httpBatchLink } from '@trpc/client';
import { authConfig, config, gmtConfig, rumConfig } from './env-exports';
import { RegistrationForm } from '@/components/RegistrationForm';
import { RumClient } from './services/rum-client';

export default function App() {
  const [captchaLoading, setCaptchaLoading] = useState(true);
  const apiUri = config.apiBaseUri ?? window.location.origin;

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${apiUri}/api/trpc`,
        }),
      ],
    }),
  );

  useEffect(() => {
    if (gmtConfig.enabled) {
      TagManager.initialize(gmtConfig.config);
    }
    if (rumConfig.appMonitorId) {
      RumClient.initialise({
        appMonitorId: rumConfig.appMonitorId,
        identityPoolId: authConfig.identityPoolId,
        guestRoleArn: authConfig.unauthRoleArn,
        sessionSampleRate: 1,
        region: authConfig.identityPoolRegion,
      });
    }
  });

  useEffect(() => {
    const scriptFullPath = `${config.captchaLibraryUrl}jsapi.js`;
    if (captchaLoading) {
      const scriptExists = Array.from(document.scripts).some((script) => script.src === scriptFullPath);
      if (scriptExists) return;
      const script = document.createElement('script');
      script.src = scriptFullPath;
      script.async = true;
      document.body.appendChild(script);
      setCaptchaLoading(false);
    }
  }, [captchaLoading]);

  return (
    <>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RegistrationForm />
        </QueryClientProvider>
      </trpc.Provider>
    </>
  );
}
