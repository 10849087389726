import { FallbackProps } from 'react-error-boundary';
import { CardWrapper } from '@/components/Common/CardWrapper';
import { helpText } from '../CommonTexts';

export const ErrorHandler = (props: FallbackProps) => {
  const { error, resetErrorBoundary } = props;

  return (
    <div className="w-full">
      <div className="h-screen flex flex-col items-center justify-center mt-0 sm:mt-10 text-sm">
        <CardWrapper title="Error!!">
          <div className="font-bold text-center">{error.message}</div>
          <button className="mt-4" onClick={resetErrorBoundary}>
            Reload Page
          </button>
        </CardWrapper>
        {helpText}
      </div>
    </div>
  );
};
