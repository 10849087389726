import { FormControl, FormField, FormItem, FormLabel, FormDescription, FormMessage } from '@/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { useElementSize } from '@/hooks';

interface SelectFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: { [key: string]: string };
}

export const SelectField = <T extends FieldValues>({ name, label, control, options }: SelectFieldProps<T>) => {
  const [triggerRef, { width: triggerWidth }] = useElementSize<HTMLButtonElement>();
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="font-semibold text-base">{label}</FormLabel>
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger
                ref={triggerRef}
                className={cn(
                  errors?.[name] ? 'border-chilli  focus:ring-chilli' : 'border-input  focus:ring-deep-blue',
                  'focus:ring-1 focus:ring-offset-0 text-left text-base',
                  !field.value && 'text-muted-foreground',
                )}
              >
                <SelectValue placeholder="Select" />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="border-deep-blue max-w-full" style={{ width: `${triggerWidth}px` }}>
              {Object.entries(options).map(([value, optionLabel]) => (
                <SelectItem
                  key={value}
                  value={value}
                  className="focus:bg-deep-blue-50 focus:text-deep-blue-50-foreground whitespace-normal overflow-hidden text-ellipsis text-base"
                >
                  {optionLabel}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormDescription className="hidden" />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
