import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';

interface InputFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  onKeyDown?: () => void;
}

export const InputField = <T extends FieldValues>({ name, label, control, onKeyDown }: InputFieldProps<T>) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="font-semibold text-base">{label}</FormLabel>
          <FormControl>
            <Input
              {...field}
              className={`${
                errors?.[name] ? 'border-chilli  focus-visible:ring-chilli' : 'border-input'
              } ring-offset-deep-blue focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0 text-base`}
              onKeyDown={onKeyDown}
            />
          </FormControl>
          <FormDescription className="hidden" />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
