import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/';
import { config } from '@/env-exports';

export interface CaptchaDialogProps {
  onCaptchaSuccess: (token: string) => void;
}
export const CaptchaDialog = ({ onCaptchaSuccess }: CaptchaDialogProps) => {
  const [captchaRendered, setCaptchaRendered] = useState(false);
  const captchaContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const container = captchaContainerRef.current;
    if (!captchaRendered && container) {
      AwsWafCaptcha.renderCaptcha(container, {
        apiKey: config.captchaApiKey,
        onSuccess: (token: string) => {
          onCaptchaSuccess(token);
        },
        dynamicWidth: true,
      });
      setCaptchaRendered(true);
    }
  }, [onCaptchaSuccess, captchaRendered]);

  useEffect(() => {
    // The code below is to hide the 3 tooltips ie Refresh, Instruction and Audio because they are displayed at the wrong position when embedded in a modal
    if (captchaRendered) {
      const captchaElement = document.querySelector('awswaf-captcha');
      const shadowRoot = captchaElement?.shadowRoot;
      if (shadowRoot) {
        const intervalId = setInterval(() => {
          const targetDivs = shadowRoot.querySelectorAll('.amzn-captcha-modal form div[style*="position: fixed"] div');

          if (targetDivs.length > 0) {
            for (const div of targetDivs) {
              (div as HTMLElement).style.display = 'none';
            }
            clearInterval(intervalId); // Stop checking once the element is found
          }
        }, 100);
      }
    }
  }, [captchaRendered]);

  return (
    <Dialog defaultOpen>
      <DialogContent
        hideXButton
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle></DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div ref={captchaContainerRef} />
      </DialogContent>
    </Dialog>
  );
};
