import { CardWrapper } from '../Common';

export const AccountRegistered = () => {
  return (
    <CardWrapper title="Thank you for registering!">
      <div className="text-center">
        Congratulations on starting your journey with Curtin.{' '}
        <br /><b>We have sent you an email so you can activate your account and set your password.</b>
        <br />
        <br />
        You can now close this window.
      </div>
    </CardWrapper>
  );
};
