import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { CurtinLogo } from '@/components/Common/Icons/CurtinLogo';

interface CardWrapperProps {
  title: String;
  children: React.ReactNode;
}

export const CardWrapper = ({ title, children }: CardWrapperProps) => {
  return (
    <Card className="w-full fullhd:w-25 xl:w-35 md:w-55 rounded-none md:rounded-2xl flex flex-col py-10 px-8 items-center justify-center gap-y-6 border-0 ">
      <CardHeader className="items-center space-y-8 p-0">
        <a href="https://curtin.edu.au" rel="noopener" aria-label="Curtin University home page">
          <CurtinLogo />
        </a>
        <div className="text-[34px]  leading-[50px] font-bold text-center">{title}</div>
      </CardHeader>
      <CardContent className="flex flex-col p-0 gap-y-6 w-full">{children}</CardContent>
    </Card>
  );
};
