export const config = {
  idpUrl: import.meta.env.VITE_IDP_URL as string,
  agentIdpUrl: import.meta.env.VITE_IDP_AGENT_URL as string,
  apiBaseUri: import.meta.env.VITE_API_BASE_URI as string | undefined,
  captchaApiKey: import.meta.env.VITE_CAPTCHA_API_KEY as string | undefined,
  captchaLibraryUrl: import.meta.env.VITE_WAF_CAPTCHA_ENDPOINT as string,
};
export const rumConfig = {
  appMonitorId: import.meta.env.VITE_RUM_APP_MONITOR_ID as string | undefined,
};

export const gmtConfig = {
  enabled: import.meta.env.VITE_GTM_ENABLED && import.meta.env.VITE_GTM_ENABLED === 'true',
  config: {
    gtmId: 'GTM-WKWRXD',
    dataLayerName: 'dataCoreLayer',
  },
};

export const authConfig = {
  identityPoolId: import.meta.env.VITE_AUTH_IDENTITY_POOL_ID as string,
  identityPoolRegion: import.meta.env.VITE_AUTH_IDENTITY_POOL_REGION as string,
  unauthRoleArn: import.meta.env.VITE_AUTH_UNAUTH_ROLE_ARN as string,
};
