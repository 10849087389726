import * as z from 'zod';

export const RegisterSchema = z.object({
  firstName: z.string().min(1, { message: 'Enter first name' }),
  lastName: z.string().min(1, { message: 'Enter last name' }),
  email: z.string().email({ message: 'Enter a valid email address' }),
  gender: z.string().min(1, { message: 'Select your gender' }),
  dob: z.union([z.date(), z.null()]).refine((value) => value !== null, {
    message: 'Select your date of birth',
  }),
  citizenship: z.string().min(1, { message: 'Select your citizenship' }),
});
