import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from '@/components/Common/ErrorHandler';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
);
