import { useState } from 'react';
import { FormControl, FormField, FormItem, FormLabel, FormDescription, FormMessage } from '@/components/ui/form';
import { Button, buttonVariants } from '@curtin-dxp/curtin-ui';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';
import { cn } from '@/lib/utils';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

interface DatePickerFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
}

export const DatePickerField = <T extends FieldValues>({ name, label, control }: DatePickerFieldProps<T>) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const {
    formState: { errors },
  } = useFormContext();

  const handleDateSelection = (date: Date | undefined, onChange: (value: Date | null) => void) => {
    if (date) {
      // Normalize to UTC to fix timezone issues
      const normalizedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      onChange(normalizedDate);
      setIsCalendarOpen(false);
    } else {
      onChange(null);
    }
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="font-semibold text-base">{label}</FormLabel>
          <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn(
                    errors?.[name]
                      ? 'border-chilli  focus-visible:ring-chilli focus:ring-chilli'
                      : 'border-input  focus-visible:ring-deep-blue focus:ring-deep-blue',
                    'w-full px-3 py-2 text-left font-normal border-[1px] focus-visible:ring-1 focus-visible:ring-offset-0  focus:ring-1 focus:ring-offset-0',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  {field.value ? format(field.value, 'dd/MM/yyyy') : <span>dd/mm/yyyy</span>}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-full p-0 border-deep-blue" align="end">
              <Calendar
                mode="single"
                captionLayout="dropdown-buttons"
                selected={field.value ? new Date(field.value) : undefined}
                onSelect={(date) => handleDateSelection(date, field.onChange)}
                fromYear={1900}
                toYear={2050}
                disabled={(date) => date > new Date() || date < new Date('1900-01-01')}
                initialFocus
                className="rounded-md"
                classNames={{
                  caption_dropdowns: 'flex justify-center gap-1',
                  caption_label: 'font-medium',
                  months: 'flex flex-col space-y-4 sm:space-x-4 sm:space-y-0',
                  nav_button: cn(
                    buttonVariants({ variant: 'outline-primary' }),
                    'h-8 w-8 bg-transparent p-2 hover:opacity-100 border-[1px]',
                  ),
                  table: 'w-full border-collapse space-y-1',
                  head_cell: 'text-[#4B4B4B] rounded-md w-10 font-semibold text-base',
                  cell: 'text-center text-base p-1 relative [&:has([aria-selected])]:bg-deep-blue [&:has([aria-selected])]:rounded-[5px] [&:has([aria-selected])]:text-white focus-within:relative focus-within:z-20',
                  day: cn(
                    buttonVariants({ variant: 'outline-secondary' }),
                    'h-8 w-8 p-0 font-normal aria-selected:opacity-100 border-0',
                  ),
                  day_selected:
                    'bg-deep-blue text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                }}
              />
            </PopoverContent>
          </Popover>
          <FormDescription className="hidden" />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
