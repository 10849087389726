import { config } from '@/env-exports';
import React from 'react';

export const formDescription: React.ReactNode = (
  <div className="text-center ">
    By clicking 'Create account' you are confirming that you have read and agree to{' '}
    <a
      href="https://informationmanagement.curtin.edu.au/privacy/#Privacy_Statement"
      className="underline font-bold"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Curtin's Privacy Statement"
    >
      Curtin's Privacy Statement
    </a>
    ,{' '}
    <a
      href="https://s30991.pcdn.co/about/wp-content/uploads/sites/5/2022/12/Information_and_Communication_Technology_ICT_Appropriate_Use_Procedures.pdf"
      className="underline font-bold"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="ICT Procedures"
    >
      ICT Procedures
    </a>
    , and{' '}
    <a
      href="https://s30991.pcdn.co/about/wp-content/uploads/sites/5/2022/12/Information_Security_and_ICT_Appropriate_Use_Policy.pdf"
      className="underline font-bold"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="ICT Policy"
    >
      ICT Policy
    </a>
    .
  </div>
);

export const formFooter: React.ReactNode = (
  <div className="flex flex-col gap-y-6 text-center">
    <div>
      Already have an account?{' '}
      <a
        href={config.idpUrl}
        className="underline font-bold"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Sign in here"
      >
        Sign in
      </a>
      .
    </div>
    <div>
      Are you an agent applying on behalf of a student?{' '}
      <a
        href={config.agentIdpUrl}
        className="underline font-bold"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Agent sign In"
      >
        Click here
      </a>
      .
    </div>
  </div>
);

export const helpText: React.ReactNode = (
  <div className="flex flex-col gap-y-[20px] items-center justify-center text-center py-10 px-8 text-white">
    <div>
      Need help? Get in touch with{' '}
      <a
        href="https://students.connect.curtin.edu.au/app/contact"
        className="underline font-bold hover:text-deep-blue-100 focus-visible:ring-offset-transparent focus-visible:ring-offset-1"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Curtin Connect"
      >
        Curtin Connect.
      </a>
    </div>
    <div>For staff members, contact DTS on +61 8 9266 9000.</div>
  </div>
);
