import { useCallback, useEffect, useState } from 'react';
import { CardWrapper, formDescription, formFooter, helpText } from '@/components/Common';
import { RegisterSchema } from '@/schema';
import { FieldValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@curtin-dxp/curtin-ui';
import { Form } from '@/components/ui/form';
import { InputField } from './InputField';
import { SelectField } from './SelectField';
import { DatePickerField } from './DatePickerField';
import { AccountRegistered } from './AccountRegistered';
import { ReloadIcon } from '@radix-ui/react-icons';
import { trpc } from '@/data-loaders';
import { StudentPayLoad } from '#server/models';
import { CaptchaDialog } from './CaptchaDialog';

export const RegistrationForm = () => {
  const [serverError, setServerError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAccountRegistered, setIsAccountRegistered] = useState<boolean>(false);
  const [showCaptchaDialog, setShowCaptchaDialog] = useState<boolean>(false);
  const [data, setData] = useState<StudentPayLoad>({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    dob: '',
    citizenship: '',
  });
  const studentCreator = trpc.registerStudent.useMutation();

  const form = useForm<FieldValues>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      gender: '',
      dob: null,
      citizenship: '',
    },
  });
  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = form;

  const genderOptions = {
    M: 'Male',
    F: 'Female',
    X: 'Indeterminate/intersex/unspecified',
  };

  const citizenOptions = {
    1: 'Australian Citizen',
    2: 'New Zealand Citizen',
    3: 'Permanent Resident of Australia',
    4: 'International Student applying to study in Australia',
    5: 'International Student applying to a campus outside Australia or Fully Online',
    8: 'Australian Permanent Humanitarian Visa Holder',
    P: 'Pacific Engagement Visa Holder',
  };

  const onSubmit = (data: FieldValues) => {
    if (isValid) {
      setIsLoading(true);
      setData(data as StudentPayLoad);
      setShowCaptchaDialog(true);
    }
  };

  const handleOnKeyDown = () => {
    if (!studentCreator.isLoading) studentCreator.reset();
  };
  const handleOnSolvingPuzzleSuccess = useCallback(() => {
    studentCreator.mutate(data as StudentPayLoad);
    setShowCaptchaDialog(false);
  }, [data, studentCreator]);

  useEffect(() => {
    if (studentCreator.error && !studentCreator.isLoading) {
      setIsLoading(false);
      switch (studentCreator.error.data?.code) {
        case 'CONFLICT':
          setError('email', { message: 'This email address is already in use' });
          break;
        default:
          setServerError(true);
          break;
      }
    } else if (studentCreator.data !== undefined && studentCreator.data) {
      setIsAccountRegistered(true);
      setIsLoading(false);
    }
  }, [setError, studentCreator.error, studentCreator.data, studentCreator.isLoading]);

  return (
    <>
      {showCaptchaDialog && isValid && <CaptchaDialog onCaptchaSuccess={handleOnSolvingPuzzleSuccess} />}
      <main className="w-full">
        <div
          className={`${isAccountRegistered ? 'h-screen' : 'md:mt-10'} flex flex-col items-center justify-center mt-0 text-base`}
        >
          {!isAccountRegistered && (
            <CardWrapper title="Create an account ">
              <Form {...form}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div className="flex flex-row gap-x-4">
                    <div className="w-1/2">
                      <InputField name="firstName" label="First name" control={form.control} />
                    </div>
                    <div className="w-1/2">
                      <InputField name="lastName" label="Last name" control={form.control} />
                    </div>
                  </div>
                  <InputField name="email" label="Email address" control={form.control} onKeyDown={handleOnKeyDown} />
                  <SelectField name="gender" label="Gender" control={form.control} options={genderOptions} />
                  <DatePickerField name="dob" label="Date of birth" control={form.control} />
                  <SelectField name="citizenship" label="Citizenship" control={form.control} options={citizenOptions} />
                  {studentCreator.error?.data?.code === 'CONFLICT' && !isValid ? (
                    <div className="p-4 rounded-md font-medium bg-[#FFD9D9] text-center">
                      {studentCreator.error.message}
                    </div>
                  ) : null}
                  {serverError ? (
                    <div className="p-4 rounded-md font-medium bg-[#FFD9D9] text-center">
                      Something went wrong. Plase try again later.
                    </div>
                  ) : null}
                  <div className="py-2">{formDescription}</div>
                  <Button type="submit" variant="filled-primary" className="w-full" disabled={isLoading}>
                    {isLoading && <ReloadIcon className="mr-2 h-5 w-5 animate-spin" />}
                    Create account
                  </Button>
                </form>
              </Form>
              <div>{formFooter}</div>
            </CardWrapper>
          )}
          {isAccountRegistered && <AccountRegistered />}
          {helpText}
        </div>
      </main>
    </>
  );
};
